import React, { useCallback, useRef, useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Box, Button, HStack, Modal, Stack, Text, useTheme, AlertDialog, Spinner, Center, VStack } from 'native-base';
import { CalendarList } from 'react-native-calendars';
import AppContainer from '../Components/AppContainer';
import Trans from '../Components/Trans';
import APIAction from '../../Actions/APIAction';

import main from '../../Assets/Styles/main.json';

const mainStyle = StyleSheet.create(main);

const AvailabilityScreen = ({ navigation }) => {

    const
        { colors } = useTheme(),
        firstLoad = useRef(true),
        [disabledDates, setDisabledDates] = useState({}),
        [modalOpen, setModalOpen] = useState(false),
        [markedDates, setMarkedDates] = useState({}),
        clickedDates = useRef([]),
        [wait, setWait] = useState(false)
        ;

    const loadData = useCallback(() => {
        const init = async () => {
            setWait(true);
            //set disabled dates
            let now = new Date();
            let currentYear = now.getFullYear();
            let currentMonth = ((now.getMonth() + 1) + '').padStart(2, '0');
            let currentDay = now.getDate();

            let newDisbaledDates = {};
            for (let i = 1; i < currentDay; i++) {
                newDisbaledDates[currentYear + '-' + currentMonth + '-' + (i + '').padStart(2, '0')] = { disabled: true, disableTouchEvent: true };
            }
            setDisabledDates(newDisbaledDates);

            //get availability
            let availabilities = await APIAction.request({
                method: 'GET',
                url: '/api/employee_availabilities'
            });
            availabilities = availabilities['hydra:member'];
            let newMarkedDates = {};

            for (let i = 0; i < availabilities.length; i++) {
                let availability = availabilities[i];
                let date = new Date(availability.date);
                let currentYear = date.getFullYear();
                let currentMonth = ((date.getMonth() + 1) + '').padStart(2, '0');
                let currentDay = (date.getDate() + '').padStart(2, '0');
                newMarkedDates[`${currentYear}-${currentMonth}-${currentDay}`] = { startingDay: true, endingDay: true, color: (availability.available ? colors['success'][600] : colors['danger'][600]), textColor: 'white' };
            }
            setMarkedDates(newMarkedDates);
            clickedDates.current = [];
            setWait(false);
        }
        init();
    }, [setDisabledDates, colors]);

    const onFirstload = useCallback(() => {
        //get data
        loadData();

    }, [loadData]);


    const clickDate = async (day) => {

        let date = new Date(day.dateString);

        let dateString = `${date.getFullYear()}-${(date.getMonth() + 1 + '').padStart(2, '0')}-${(date.getDate() + '').padStart(2, '0')}`;
        if (!clickedDates.current.includes(dateString)) {
            clickedDates.current = [...clickedDates.current, dateString];
        }


        //automatically cycle

        if (markedDates[dateString]) {  //if already marked
            if (markedDates[dateString].color === colors['success'][600]) {
                onAvailable(false, date);
            } else if (markedDates[dateString].color === colors['danger'][600]) {
                onAvailable(null, date);
            }
        } else {
            onAvailable(true, date);
        }

        //don't ask (yet), uncomment to ask
        //setModalOpen(true);
    }

    const onAvailable = async (is, date) => {

        let currentDate = new Date(date);
        let currentYear = currentDate.getFullYear();
        let currentMonth = ((currentDate.getMonth() + 1) + '').padStart(2, '0');
        let currentDay = (currentDate.getDate() + '').padStart(2, '0');

        if (is !== null) {

            //set in calendar
            let newMarkedDate = {};
            newMarkedDate[`${currentYear}-${currentMonth}-${currentDay}`] = { startingDay: true, endingDay: true, color: (is ? colors['success'][600] : colors['danger'][600]), textColor: 'white' };
            let newMarkedDates = { ...markedDates, ...newMarkedDate };
            setMarkedDates(newMarkedDates);


        } else {
            let newMarkedDates = JSON.parse(JSON.stringify(markedDates));
            delete newMarkedDates[`${currentYear}-${currentMonth}-${currentDay}`];
            setMarkedDates(newMarkedDates);
        }
    }

    const onSave = useCallback(async () => {
        setWait(true);
        //get availability
        let availabilities = await APIAction.request({
            method: 'GET',
            url: '/api/employee_availabilities'
        });
        availabilities = availabilities['hydra:member'];

        let requests = [];

        //save all clicked dates
        for (let clickDate of clickedDates.current) {

            //unmarked dates
            if (!markedDates[clickDate]) {
                let dateItem = clickDate.split('-');
                let availabilityOnDate = null;
                for (let i = 0; i < availabilities.length; i++) {
                    let availability = availabilities[i];
                    let availabilityDate = new Date(availability.date);

                    if (
                        (availabilityDate.getFullYear() === parseInt(dateItem[0])) &&
                        (availabilityDate.getMonth() === (parseInt(dateItem[1]) - 1)) &&
                        (availabilityDate.getDate() === parseInt(dateItem[2]))
                    ) {
                        availabilityOnDate = availability;
                        break;
                    }
                }

                if (availabilityOnDate) {
                    //send request
                    requests.push(APIAction.request({
                        method: 'DELETE',
                        url: '/api/employee_availabilities/' + availabilityOnDate.id
                    }));
                }
            }

            //marked dates
            else {
                let markedDate = markedDates[clickDate];
                let available = false;
                if (markedDate.color === colors['success'][600]) available = true;

                //send request
                requests.push(await APIAction.request({
                    method: 'POST',
                    url: '/api/employee_availabilities',
                    body: {
                        date: `${clickDate}T12:00:00+02:00`,
                        available: available
                    }
                }));


            }
        }
        await Promise.all(requests);
        clickedDates.current = [];
        setWait(false);
    }, [markedDates, setWait, colors]);



    useEffect(() => {
        if (firstLoad.current) {
            firstLoad.current = false;
            onFirstload();
        }
    }, [onFirstload]);

    //check if there are unsaved changes
    useEffect(() => navigation.addListener('blur', (e) => {
        onSave();
    }), [navigation, onSave]);

    return (
        <AppContainer noScroll>
            <VStack flex={1}>
                <Box flexGrow={1} flexShrink={1}>
                    <CalendarList
                        calendarWidth={'100%'}
                        showWeekNumbers={false}
                        scrollEnabled={true}
                        pagingEnabled={false}
                        showScrollIndicator={true}
                        pastScrollRange={0}
                        futureScrollRange={6}
                        firstDay={1}
                        markingType={'period'}
                        markedDates={{
                            ...disabledDates,
                            ...markedDates
                        }}
                        onDayPress={(day) => { setTimeout(() => clickDate(day), 0) }}
                    />
                </Box>
                <HStack space={2} mt={3} justifyContent={'flex-end'}>
                    <Button
                        flexGrow={{
                            base: 1,
                            md: 0
                        }}
                        colorScheme={'danger'}
                        onPress={() => {
                            loadData();
                        }}
                    >
                        <Text color={'white'}><Trans>Cancel</Trans></Text>
                    </Button>
                    <Button
                        flexGrow={{
                            base: 1,
                            md: 0
                        }}
                        colorScheme={'success'}
                        onPress={() => {
                            onSave();
                        }}
                    >
                        <Text color={'white'}><Trans>Submit</Trans></Text>
                    </Button>
                </HStack>
            </VStack>
            <Modal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                size={'lg'}
            >
                <Modal.Content>
                    <Modal.CloseButton />
                    <Modal.Header>
                        <Text style={[mainStyle.modalTitle]}><Trans>Availability</Trans></Text>
                    </Modal.Header>
                    <Modal.Body>
                        <Text><Trans>Are you available on this day?</Trans></Text>
                    </Modal.Body>
                    <Modal.Footer>
                        <Stack
                            flex={1}
                            space={2}
                            direction={{
                                base: 'column',
                                md: 'row'
                            }}
                        >
                            <Box flex={1}>
                                <Button colorScheme={'success'} flex={1} onPress={() => {
                                    onAvailable(true);
                                    setModalOpen(false);
                                }}>
                                    <Text color={'white'}><Trans>Yes</Trans></Text>
                                </Button>
                            </Box>
                            <Box flex={1}>
                                <Button colorScheme={'danger'} flex={1} onPress={() => {
                                    onAvailable(false);
                                    setModalOpen(false);
                                }}>
                                    <Text color={'white'}><Trans>No</Trans></Text>
                                </Button>
                            </Box>
                            <Box flex={1}>
                                <Button colorScheme={'dark'} flex={1} onPress={() => {
                                    onAvailable(null);
                                    setModalOpen(false);
                                }}>
                                    <Text><Trans>No information</Trans></Text>
                                </Button>
                            </Box>
                        </Stack>
                    </Modal.Footer>
                </Modal.Content>
            </Modal>
            <AlertDialog isOpen={wait}>
                <AlertDialog.Content>
                    <AlertDialog.Body>
                        <Spinner size={'lg'} />
                        <Center>
                            <Text>
                                <Trans>Please wait...</Trans>
                            </Text>
                        </Center>
                    </AlertDialog.Body>
                </AlertDialog.Content>
            </AlertDialog>
        </AppContainer>
    );
};

export default AvailabilityScreen;